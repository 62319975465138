import React from "react";

import { MapNavigatorItem, useLocales } from "hooks";
import { Badge } from "components";
import { displayDate } from "utils";

export interface MapNavigatorMeasureRegionProps {
  canton: MapNavigatorItem;
  region: MapNavigatorItem;
}

export function MapNavigatorMeasureRegion({
  canton,
  region,
}: MapNavigatorMeasureRegionProps): JSX.Element {
  const { t } = useLocales();
  return (
    <div className="navigator-detail">
      <div className="header-section">
        <Badge type="measures" level={region.level} size="big" />

        <div>
          <h2>{region.name}</h2>
          <h3>{canton.name || ""}</h3>
        </div>
      </div>

      <div className="section">
        <h4>{t("navigator.measure")}</h4>
        <p>
          {region.measure?.title ||
            region.measure?.shortTitle ||
            region.levelName}
        </p>
      </div>

      <div className="section">
        <h4>{t("navigator.measure_meaning")}</h4>
        <p>{region.measure?.description}</p>
      </div>

      {region.validFrom && (
        <div className="section">
          <h4>{t("navigator.activeSince")}</h4>
          <p>{displayDate(region.validFrom)}</p>
        </div>
      )}
    </div>
  );
}
