import React from "react";

import { MapKind } from "components";
import { MapNavigatorItem, useLocales } from "hooks";
import { MapNavigatorListItem } from "./MapNavigatorListItem";

export interface MapNavigatorCantonsListProps {
  type: MapKind;
  cantons: ReadonlyArray<MapNavigatorItem>;
  onCantonClick: (canton: MapNavigatorItem) => void;
}

export function MapNavigatorCantonsList({
  type,
  cantons,
  onCantonClick,
}: MapNavigatorCantonsListProps): JSX.Element {
  const { t } = useLocales();

  function getDescription(canton: MapNavigatorItem): string {
    let level = canton.levelName;
    if (type === "warnMap") {
      level = canton.level
        ? t(`navigator.warnMap.${canton.level}.title`)
        : undefined;
    }
    return level ?? "";
  }

  return (
    <>
      {cantons.map((canton) => (
        <MapNavigatorListItem
          key={String(canton.id)}
          type={type}
          item={canton}
          description={getDescription(canton)}
          onClick={onCantonClick}
        />
      ))}
    </>
  );
}
