import React from "react";

import { MapKind } from "components";
import { MapNavigatorItem, useLocales } from "hooks";
import { MapNavigatorListItem } from "./MapNavigatorListItem";
import { displayDate } from "utils";

export interface MapNavigatorRegionsListProps {
  type: MapKind;
  regions: ReadonlyArray<MapNavigatorItem>;
  onRegionClick: (region: MapNavigatorItem) => void;
}

export function MapNavigatorRegionsList({
  type,
  regions,
  onRegionClick,
}: MapNavigatorRegionsListProps): JSX.Element {
  const { t } = useLocales();

  if (regions.length === 0) {
    return <p className="navigator-no-entries">{t("navigator.no_entries")}</p>;
  }

  function getDescription(region: MapNavigatorItem): string {
    let level = region.levelName;
    if (type === "warnMap") {
      level = region.level
        ? t(`navigator.warnMap.${region.level}.title`)
        : undefined;
    }

    if (level && region.validFrom) {
      return t("navigator.item_description", {
        level,
        date: displayDate(region.validFrom),
      });
    } else {
      return level ?? "";
    }
  }

  return (
    <>
      {regions.map((region) => (
        <MapNavigatorListItem
          key={String(region.id)}
          type={type}
          item={region}
          description={getDescription(region)}
          onClick={onRegionClick}
        />
      ))}
    </>
  );
}
