import React from "react";

import { MapNavigatorItem } from "hooks";
import { Badge, MapKind } from "components";

/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrow-right.svg";

export interface MapNavigatorListItemProps {
  type: MapKind;
  item: MapNavigatorItem;
  description: string;
  onClick: (item: MapNavigatorItem) => void;
}

export function MapNavigatorListItem({
  type,
  item,
  description,
  onClick,
}: MapNavigatorListItemProps): JSX.Element {
  return (
    <button
      className={`navigator-item ${type} level-${item.level ?? 0}`}
      onClick={() => onClick(item)}
    >
      <Badge
        type={type}
        level={item.level}
        minLevel={item.minLevel}
        size="large"
      />
      <div className="navigator-item-content">
        <div className="navigator-item-name" title={item.name}>
          {item.name}
        </div>
        <div className="navigator-item-description" title={description}>
          {description}
        </div>
      </div>
      <ArrowRightIcon />
    </button>
  );
}
