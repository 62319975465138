import React from "react";

import { Badge } from "components";
import { MapNavigatorItem, useLocales } from "hooks";
import { displayDate } from "utils";

export interface MapNavigatorWarnMapRegionProps {
  canton: MapNavigatorItem;
  region: MapNavigatorItem;
}

export function MapNavigatorWarnMapRegion({
  region,
}: MapNavigatorWarnMapRegionProps): JSX.Element | null {
  const { t } = useLocales();

  return (
    <div className="navigator-detail">
      <div className="header-section">
        <Badge type={"warnMap"} level={region.level} size="big" />

        <div>
          <h2>{region.name}</h2>
          {region.level && (
            <h3>{t(`navigator.warnMap.${region.level}.title`)}</h3>
          )}
        </div>
      </div>

      {region.level && (
        <>
          <div className="section">
            <h4>{t("navigator.cause")}</h4>
            <p>{t(`navigator.warnMap.${region.level}.cause`)}</p>
          </div>

          <div className="section">
            <h4>{t("navigator.propagation_speed")}</h4>
            <p>{t(`navigator.warnMap.${region.level}.propagation_speed`)}</p>
          </div>

          <div className="section">
            <h4>{t("navigator.characteristics")}</h4>
            <p>{t(`navigator.warnMap.${region.level}.characteristics`)}</p>
          </div>

          <div className="section">
            <h4>{t("navigator.combat")}</h4>
            <p>{t(`navigator.warnMap.${region.level}.combat`)}</p>
          </div>

          {region.validFrom && (
            <div className="section">
              <h4>{t("navigator.activeSince")}</h4>
              <p>{displayDate(region.validFrom)}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
