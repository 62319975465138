import React, { useEffect, useMemo, useRef, useState } from "react";
import { FeatureLike } from "ol/Feature";

import { Canton, MapKind } from "components";
import {
  MapNavigatorItem,
  useLocales,
  useMapNavigatorSortedItems,
  usePreviousValue,
} from "hooks";
import { MapNavigatorSortHeader } from "./MapNavigatorSortHeader";
import { MapNavigatorCantonsList } from "./MapNavigatorCantonsList";
import { MapNavigatorRegionsList } from "./MapNavigatorRegionsList";
import { MapNavigatorWarnMapRegion } from "./MapNavigatorWarnMapRegion";
import { MapNavigatorMeasureRegion } from "./MapNavigatorMeasureRegion";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";

export interface MapNavigatorProps {
  type: MapKind;
  cantons: ReadonlyArray<Canton>;
  regionsJsonPath?: string;
  measuresJsonPath?: string;
  selection?: FeatureLike | null;
  onSelectionChange?: (regionId: number | null) => void;
  features?: ReadonlyArray<FeatureLike>;
}

export function MapNavigator({
  type,
  cantons,
  regionsJsonPath,
  measuresJsonPath,
  selection,
  onSelectionChange,
  features,
}: MapNavigatorProps): JSX.Element | null {
  const { t } = useLocales();
  const { cantonItems, regionItems, sorting, setSorting } =
    useMapNavigatorSortedItems({
      type,
      cantons,
      regionsJsonPath,
      measuresJsonPath,
      features,
    });

  const [cantonItem, setCantonItem] = useState<MapNavigatorItem | null>(null);
  const [regionItem, setRegionItem] = useState<MapNavigatorItem | null>(null);
  const regionItemRef = useRef<MapNavigatorItem | null>(null);
  regionItemRef.current = regionItem;
  const previousRegionItem = usePreviousValue(regionItem);

  const panel = useRef<HTMLDivElement>(null);
  const scroll = useRef({ canton: 0, region: 0 });

  // Set cantonItem & regionItem if selection changes
  useEffect(() => {
    if (!selection && !regionItemRef.current) {
      // When user navigates back from detail, ignore selection
      // clearing & stay on regions list
      return;
    }

    const regionId =
      selection &&
      (type === "warnMap"
        ? selection.getProperties().fire_region_id
        : selection.getProperties().id);
    const selectedRegion =
      (regionId && regionItems.find((r) => r.id === regionId)) || null;
    const selectedCanton =
      (selectedRegion?.parentId &&
        cantonItems.find((c) => c.id === selectedRegion.parentId)) ||
      null;
    resetScroll();
    setRegionItem(selectedRegion);
    setCantonItem(selectedCanton);
  }, [
    selection,
    regionItemRef,
    type,
    cantonItems,
    regionItems,
    setCantonItem,
    setRegionItem,
  ]);

  // Clear the selection if the user navigates back to regions list
  useEffect(() => {
    if (onSelectionChange && !regionItem && previousRegionItem && selection) {
      onSelectionChange(null);
    }
  }, [onSelectionChange, previousRegionItem, regionItem, selection]);

  useEffect(
    () => restoreScroll(cantonItem, regionItem),
    [cantonItem, regionItem]
  );

  // Scroll to top on sorting change
  useEffect(() => {
    if (panel.current) {
      panel.current.scrollTop = 0;
    }
    resetScroll();
  }, [sorting]);

  // Regions of current canton
  const cantonRegionItems = useMemo(
    () =>
      cantonItem ? regionItems.filter((r) => r.parentId === cantonItem.id) : [],
    [regionItems, cantonItem]
  );

  function resetScroll(): void {
    if (panel.current) {
      scroll.current.canton = 0;
      scroll.current.region = 0;
    }
  }

  function storeCantonScroll(): void {
    if (panel.current) {
      scroll.current.canton = panel.current.scrollTop;
    }
  }

  function storeRegionScroll(): void {
    if (panel.current) {
      scroll.current.region = panel.current.scrollTop;
    }
  }

  function restoreScroll(
    canton: MapNavigatorItem | null,
    region: MapNavigatorItem | null
  ): void {
    if (!panel.current) {
      return;
    }

    if (!canton && !region) {
      // On cantons list
      panel.current.scrollTop = scroll.current.canton;
      scroll.current.canton = 0;
      scroll.current.region = 0;
    } else if (canton && !region) {
      // On regions list
      panel.current.scrollTop = scroll.current.region;
      scroll.current.region = 0;
    } else if (canton && region) {
      // On region detail
      panel.current.scrollTop = 0;
    }
  }

  function renderHeader(): React.ReactNode {
    if (!cantonItem && !regionItem) {
      return (
        <MapNavigatorSortHeader
          type={type}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      );
    }

    return (
      <button className="navigator-back" onClick={handleBack}>
        <ArrowLeftIcon />
        <div>{t("navigator.back")}</div>
      </button>
    );
  }

  function renderList(): React.ReactNode {
    if (cantonItem && regionItem) {
      const RegionDetail =
        type === "measures"
          ? MapNavigatorMeasureRegion
          : MapNavigatorWarnMapRegion;
      return <RegionDetail canton={cantonItem} region={regionItem} />;
    } else if (cantonItem) {
      return (
        <MapNavigatorRegionsList
          type={type}
          regions={cantonRegionItems}
          onRegionClick={handleRegionClick}
        />
      );
    }
    return (
      <MapNavigatorCantonsList
        type={type}
        cantons={cantonItems}
        onCantonClick={handleCantonClick}
      />
    );
  }

  function handleBack() {
    if (cantonItem && regionItem) {
      setRegionItem(null);
    } else if (cantonItem) {
      setCantonItem(null);
    }
  }

  function handleCantonClick(canton: MapNavigatorItem): void {
    storeCantonScroll();
    setCantonItem(canton);
  }

  function handleRegionClick(region: MapNavigatorItem): void {
    storeRegionScroll();
    setRegionItem(region);
    onSelectionChange && onSelectionChange(region.id);
  }

  return (
    <div className="navigator-panel" ref={panel}>
      {renderHeader()}
      {renderList()}
    </div>
  );
}
